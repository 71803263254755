.askvit{
    width:'100%';
    min-width:fit-content;
}

.askvit__contents {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    background-color: rgba(0, 0, 0, 0.05);
    min-width: fit-content;
    width: 100%;
  }
  
  .askvit__content {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0px 10px;
    width: 100%;
    max-width: 1200px;
  }
   .react-responsive-modal-modal {
    width: 50vw;
    height: 70vh;
  }
  
  .quill {
    height: 50vh;
  }
  
  .react-responsive-modal-overlay {
    background: rgb(0 0 0 / 75%);
  }
